
<template>
	<v-card outlined flat v-if="rowObject">
		<v-toolbar flat dense :color="bgColor">
			<v-toolbar-items>
				<v-btn
					icon
					color="primary"
					:disabled="updateActive"
					v-on:click.stop="beginUpdate()"
					><v-icon>mdi-pencil-outline</v-icon>
				</v-btn>
				<v-btn
					icon
					:disabled="!dataModified"
					v-on:click.stop="saveChanges()"
					color="green"
					><v-icon>mdi-check-outline</v-icon></v-btn
				>
				<v-btn
					icon
					color="red"
					:disabled="!updateActive"
					@click.stop="cancelUpdate()"
					><v-icon>mdi-close</v-icon></v-btn
				>
			</v-toolbar-items>
		</v-toolbar>
		<v-divider />
		<v-card-text>
			<vue-editor
				v-model="rowObject.index_descr_long"
				v-on:text-change="onDataModified"
				:editorToolbar="customToolbar"
				:disabled="!updateActive"
			></vue-editor>
		</v-card-text>
	</v-card>
</template>
<script>
	import { mapGetters } from "vuex";
	import { VueEditor } from "vue2-editor";
	import caas from "@/services/caas";
	export default {
		name: "CatalogIndexDescr",
		components: { VueEditor },
		props: {
			rowObject: Object,
		},
		data() {
			return {
				loading: false,
				customToolbar: [
					["bold", "italic", "underline"],
					[{ list: "ordered" }, { list: "bullet" }],
					["link"],
				],
				updateActive: false,
				dataModified: false,
				rowObjectBefore: {},
			};
		},
		computed: {
			...mapGetters({
				bgColor: "session/bgColor",
			}),
		},
		methods: {
			beginUpdate() {
				Object.assign(this.rowObjectBefore, this.rowObject);
				this.rowObjectBefore.RowMod = "";
				this.rowObject.RowMod = "U";
				this.updateActive = true;
			},
			cancelUpdate() {
				this.updateActive = false;
				Object.assign(this.rowObject, this.rowObjectBefore);
				this.dataModified = false;
			},
			onDataModified() {
				this.dataModified = true;
			},
			saveChanges() {
				// server update
				let sdoName = "web/objects/dECatIndexDescr.r",
					params = {
						action: "update",
						rowObjUpd: [],
					};
				params.rowObjUpd.push(this.rowObjectBefore);
				params.rowObjUpd.push(this.rowObject);
				caas.sdo(sdoName, params)
					.then((result) => {
						if (result.data.success) {
							this.dataModified = false;
							this.updateActive = false;
							this.$store.dispatch("session/showSuccess");
						}
					})
					.catch((error) => {
						this.$store.dispatch("session/showBottomMessage", error);
					})
					.finally(() => {
						this.loading = false;
					});
			},
		},
	};
</script>