<template>
	<v-container class="pa-0" fluid>
		<v-row no-gutters :style="stickyTop" class="white">
			<v-col cols="12">
				<v-container class="pa-0">
					<v-toolbar flat dense>
						<v-toolbar-title>Product categorieën </v-toolbar-title>
						<v-toolbar-items>
							<v-icon class="ml-2" @click="fetchCatalog"
								>mdi-refresh</v-icon
							>
						</v-toolbar-items>
						<v-spacer />
					</v-toolbar>
				</v-container>
			</v-col>
			<!-- DIVIDER -->
			<v-col cols="12">
				<v-progress-linear
					v-if="loading"
					active
					indeterminate
					rounded
				></v-progress-linear>
				<v-divider v-else class="accent" style="padding-top: 2px" />
			</v-col>
		</v-row>
		<v-container>
			<v-row>
				<v-col cols="12" md="3">
					<v-card
						:max-height="tvHeight"
						flat
						outlined
						style="overflow-y: auto"
					>
						<v-card-title>
							<v-text-field
								v-model="searchCategory"
								append-icon="mdi-magnify"
								clearable
								hide-details="auto"
								flat
								dense
							/>
						</v-card-title>
						<v-treeview
							class="text-body-2"
							hoverable
							dense
							activatable
							return-object
							:search="searchCategory"
							:items="catalogTV"
							:item-key="catalogTV.id"
							@update:active="onSelectCategory"
						/>
					</v-card>
				</v-col>
				<v-col cols="12" md="9">
					<v-card v-if="catalogIndexId">
						<v-toolbar flat dense>
							<v-toolbar-title>
								<span
									>{{ catalogIndex.index_id }} -
									{{ catalogIndex.index_name }}</span
								>
							</v-toolbar-title>
							<template #extension>
								<v-tabs
									v-model="tab"
									v-on:change="onTabChanged"
									grow
									center-active
								>
									<v-tab
										v-for="tabItem in tabs"
										:key="tabItem.id"
									>
										{{ tabItem.label }}
									</v-tab>
								</v-tabs>
							</template>
						</v-toolbar>
						<v-divider />
						<v-tabs-items v-model="tab" mandatory>
							<v-tab-item :key="0">
								<catalog-index-index
									:rowObject="catalogIndex"
								/>
							</v-tab-item>
							<v-tab-item :key="1">
								<catalog-index-descr
									:rowObject="catalogDescription"
								/>
							</v-tab-item>
							<v-tab-item :key="2">
								<catalog-entries />
							</v-tab-item>
						</v-tabs-items>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</v-container>
</template>

<script>
	import { mapGetters } from "vuex";
	import CatalogIndexIndex from "@/components/CatalogIndexIndex";
	import CatalogIndexDescr from "../components/CatalogIndexDescr.vue";
	import CatalogEntries from "@/components/CatalogEntries";
	export default {
		name: "CatalogIndex",
		components: { CatalogIndexIndex, CatalogIndexDescr, CatalogEntries },
		data() {
			return {
				loading: false,
				tab: 0,
				tabs: [
					{ id: 0, label: "Categorie" },
					{ id: 1, label: "Beschrijvingen" },
					{ id: 2, label: "Items" },
				],
				searchCategory: "",
			};
		},
		computed: {
			...mapGetters({
				catalogTV: "catalog/categoryList",
				catalogIndex: "catalogIndex/getCatalogIndex",
				catalogIndexId: "catalogIndex/getCatalogIndexId",
				catalogDescription: "catalogIndex/getCatalogDescription",
				bgColor: "session/bgColor",
				contentHeight: "session/contentHeight",
			}),
			tvHeight() {
				return this.contentHeight - 100 + "px";
			},
			stickyTop() {
				return {
					position: "sticky",
					top: this.$vuetify.application.top + 0 + "px",
					zIndex: 1,
				};
			},
		},
		mounted() {
			this.fetchCatalog();
		},
		methods: {
			onTabChanged() {
				switch (this.tab) {
					case 1:
						this.$store.dispatch(
							"catalogIndex/fetchCatalogDescription"
						);
						break;
					case 2:
						this.loading = true;
						this.$store
							.dispatch("catalogIndex/fetchCatalogEntries")
							.finally(() => (this.loading = false));
						break;
				}
			},
			fetchCatalog() {
				this.loading = true;
				this.$store
					.dispatch("catalog/fetchCatalogList")
					.finally(() => (this.loading = false));
			},
			onSelectCategory(_index) {
				this.$store.commit("catalogIndex/setCurrentIndex", _index[0].id);
				this.$store.dispatch("catalogIndex/fetchCatalogIndex").then(() => {
					this.onTabChanged();
				});
			},
		},
	};
</script>

