<template>
	<v-card flat>
		<v-toolbar flat dense :color="bgColor">
			<v-toolbar-items>
				<v-btn
					icon
					color="primary"
					:disabled="updateActive"
					v-on:click.stop="beginUpdate()"
					><v-icon>mdi-pencil-outline</v-icon>
				</v-btn>
				<v-btn
					icon
					:disabled="!dataModified"
					v-on:click.stop="saveChanges()"
					color="green"
					><v-icon>mdi-check-outline</v-icon></v-btn
				>
				<v-btn
					icon
					color="red"
					:disabled="!updateActive"
					@click.stop="cancelUpdate()"
					><v-icon>mdi-close</v-icon></v-btn
				>
			</v-toolbar-items>
		</v-toolbar>
		<v-divider />
		<v-card-text>
			<v-form :readonly="!updateActive">
				<v-row>
					<v-col cols="12">
						<v-text-field
							label="Code"
							v-model="rowObject.index_code"
							hide-details="auto"
							@input="handleInput"
						/>
					</v-col>
					<v-col cols="12">
						<v-text-field
							label="Naam"
							v-model="rowObject.index_name"
							hide-details="auto"
							@input="handleInput"
						/>
					</v-col>
					<v-col cols="6">
						<v-autocomplete
							label="Positie (onder)"
							v-model="rowObject.parent_id"
							hide-details="auto"
							:items="categoryItems"
							@input="handleInput"
						/>
					</v-col>
					<v-col cols="6">
						<v-text-field
							label="Positie (volgnr)"
							type="Number"
							v-model="rowObject.parent_seq_nr"
							hide-details="auto"
							@input="handleInput"
						/>
					</v-col>
				</v-row>
			</v-form>
		</v-card-text>
	</v-card>
</template>
<script>
	import { mapGetters } from "vuex";
	import caas from "@/services/caas";
	export default {
		name: "CatalogIndexIndex",
		props: {
			rowObject: Object,
		},
		data() {
			return {
				loading: false,
				updateActive: false,
				dataModified: false,
				currentRowObject: {},
				currentRowObjectBefore: {},
			};
		},
		computed: {
			...mapGetters({
				categoryList: "catalog/categoryList",
				currentCategoryName: "catalog/currentCategoryName",
				bgColor: "session/bgColor",
			}),
			categoryItems() {
				return this.categoryList.map((v) => ({
					text: v.name,
					value: v.id,
				}));
			},
		},
		methods: {
			handleInput() {
				this.dataModified = true;
			},
			beginUpdate() {
				this.updateActive = true;
				Object.assign(this.currentRowObjectBefore, this.rowObject);
			},
			cancelUpdate() {
				this.updateActive = false;
				this.dataModified = false;
				Object.assign(this.rowObject, this.currentRowObjectBefore);
			},
			saveChanges() {
				// server update
				let sdoName = "web/objects/dECatIndex.r",
					params = {
						action: "update",
						rowObjUpd: [],
					};
				this.rowObject.RowMod = "U";
				params.rowObjUpd.push(this.rowObject);
				params.rowObjUpd.push(this.currentRowObjectBefore);
				this.loading = true;
				caas.sdo(sdoName, params)
					.then((result) => {
						if (result.data.success) {
							this.$store.dispatch("session/showSuccess");
							this.updateActive = false;
							this.dataModified = false;
							this.currentRowObjectBefore = {};
							this.rowObject.RowMod = "";
						}
					})
					.catch((error) => {
						this.$store.dispatch("session/showBottomMessage", error);
					})
					.finally(() => {
						this.loading = false;
					});
			},
		},
	};
</script>
