<template>
	<v-container>
		<v-row>
			<v-toolbar flat dense :color="bgColor">
				<v-toolbar-items>
					<v-btn
						icon
						color="primary"
						:disabled="updateActive"
						v-on:click.stop="openDialogAdd()"
						><v-icon>mdi-plus</v-icon>
					</v-btn>
					<v-btn
						icon
						color="primary"
						:disabled="updateActive"
						v-on:click.stop="beginUpdate()"
						><v-icon>mdi-pencil-outline</v-icon>
					</v-btn>
					<v-btn
						icon
						:disabled="!dataModified"
						v-on:click.stop="saveChanges()"
						color="green"
						><v-icon>mdi-check-outline</v-icon></v-btn
					>
					<v-btn
						icon
						color="red"
						:disabled="!updateActive"
						@click.stop="cancelUpdate()"
						><v-icon>mdi-close</v-icon></v-btn
					>
				</v-toolbar-items>
			</v-toolbar>
			<!-- DIALOG ADDITEM -->
			<v-dialog v-model="dialogAdd">
				<v-card
					width="500"
					max-width="100%"
					max-height="100%"
					class="mx-auto"
				>
					<v-card-title>
						item toevoegen <v-spacer />
						<v-icon @click="closeDialogAdd()"
							>mdi-close-circle</v-icon
						></v-card-title
					>
					<v-card-subtitle
						>zoek en selecteer een item om toe te
						voegen</v-card-subtitle
					>
					<v-divider />
					<v-card-text>
						<v-text-field
							v-model="searchText"
							:loading="loading"
							flat
							dense
							hide-details
							full-width
							clearable
							placeholder="item naam"
							append-icon="mdi-magnify"
							@keyup.native.enter="searchItems"
							@click:append="searchItems"
							@click:clear="clearSearch"
						></v-text-field>
						<v-data-table
							height="400"
							fixed-header
							dense
							:headers="headers"
							:items="itemSearch"
							item-key="item_id"
							hide-default-header
							hide-default-footer
							no-data-text=""
							show-select
							disable-pagination
							v-model="newItems"
						>
							<template
								v-slot:[`item.is_visible_website`]="{ item }"
							>
								<v-icon
									v-if="!item.is_visible_website"
									color="warning"
									>mdi-cloud-off-outline</v-icon
								>
							</template>
						</v-data-table>
					</v-card-text>
					<v-divider />
					<v-card-actions>
						<v-btn
							text
							color="primary"
							:disabled="!newItems.length"
							@click="addItem()"
						>
							bevestigen
						</v-btn>
						<v-spacer />
						<v-btn text plain @click="closeDialogAdd">
							annuleren
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<!-- DIALOG DELETEITEM -->
			<v-dialog v-model="dialogDelete" width="auto">
				<v-card :loading="loading">
					<v-card-title class="text-subtitle-1">
						{{ currentRowObject.item_name }} &nbsp;
						<span>: item verwijderen? </span>
					</v-card-title>
					<v-divider />
					<v-card-actions>
						<v-btn text color="primary" @click="deleteItem()"
							>Verwijderen</v-btn
						>
						<v-spacer />
						<v-btn text plain @click="closeDialogDelete()"
							>Annuleren</v-btn
						>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-row>
		<v-row>
			<v-col cols="12">
				<draggable
					v-model="rowObject"
					@change="beginUpdate"
					:disabled="!updateActive"
				>
					<v-card
						class="ma-2"
						outlined
						v-for="_rowObject in rowObject"
						:key="_rowObject.RowNum"
					>
						<v-card-title>
							<v-btn icon
								><v-icon
									>mdi-drag-horizontal-variant</v-icon
								></v-btn
							>
							<span class="text-subtitle-1">{{
								_rowObject.item_name
							}}</span>
							<v-spacer />
							<v-icon small @click="openDialogDelete(_rowObject)"
								>mdi-delete</v-icon
							>
						</v-card-title>
					</v-card>
				</draggable>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
	import draggable from "vuedraggable";
	import { mapGetters, mapMutations } from "vuex";
	import caas from "@/services/caas";
	export default {
		name: "CatalogEntries",
		components: { draggable },
		data() {
			return {
				searchText: "",
				itemSearch: [],
				dialogAdd: false,
				dialogDelete: false,
				loading: false,
				newItems: [],
				updateActive: false,
				dataModified: false,
				currentRowObject: {},
				rowObjectBefore: [],
				headers: [
					{ text: "Id", value: "item_id" },
					{ text: "Naam", value: "item_name" },
					{ text: "Website", value: "is_visible_website" },
				],
			};
		},
		computed: {
			...mapGetters({
				bgColor: "session/bgColor",
				indexId: "catalogIndex/getCatalogIndexId",
			}),
			rowObject: {
				get() {
					return this.$store.getters["catalogIndex/getCatalogEntries"];
				},
				set(_rowObject) {
					this.$store.commit(
						"catalogIndex/setCatalogEntries",
						_rowObject
					);
				},
			},
		},
		methods: {
			addItem() {
				const sdoName = "web/objects/dECatEntry.r";
				let params = {
					action: "create",
					rowObjUpd: [],
				};
				this.newItems.forEach((newItem) => {
					let x = {
						RowMod: "A",
						item_id: newItem.item_id,
						index_id: this.indexId,
					};
					params.rowObjUpd.push(x);
				});
				this.loading = true;
				caas.sdo(sdoName, params)
					.then((response) => {
						if (response.data.success) {
							this.$store.dispatch(
								"catalogIndex/fetchCatalogEntries"
							);
							this.$store.dispatch("session/showSuccess");
						} else if (response.data.error) {
							this.$store.dispatch(
								"session/showBottomMessage",
								response.data.error
							);
						}
					})
					.finally(() => {
						this.loading = false;
						this.closeDialogAdd();
					});
			},
			deleteItem() {
				const sdoName = "web/objects/dECatEntry.r";
				let params = {
					action: "delete",
					rowObjUpd: [],
				};
				this.currentRowObject.RowMod = "D";
				params.rowObjUpd.push(this.currentRowObject);
				this.loading = true;
				caas.sdo(sdoName, params)
					.then((response) => {
						if (response.data.success) {
							this.$store.dispatch(
								"catalogIndex/fetchCatalogEntries"
							);
							this.$store.dispatch("session/showSuccess");
						} else if (response.data.error) {
							this.$store.dispatch(
								"session/showBottomMessage",
								response.data.error
							);
						}
					})
					.finally(() => {
						this.loading = false;
						this.closeDialogDelete();
					});
			},
			openDialogAdd() {
				this.clearSearch();
				this.dialogAdd = true;
			},
			openDialogDelete(_rowObject) {
				this.currentRowObject = _rowObject;
				this.dialogDelete = true;
			},
			closeDialogAdd() {
				this.clearSearch();
				this.newItems = [];
				this.dialogAdd = false;
			},
			closeDialogDelete() {
				this.dialogDelete = false;
				this.currentRowObject = {};
			},
			clearSearch() {
				this.searchText = "";
				this.itemSearch = [];
			},
			searchItems() {
				const sdoName = "web/objects/dECatItem.r";
				const params = {
					action: "read",
					nbrOfRows: 999,
					setOpenQuery:
						"for each ecat_item where item_name contains '" +
						this.searchText +
						"* ' no-lock indexed-reposition",
				};
				this.loading = true;
				caas.sdo(sdoName, params)
					.then((response) => {
						if (response.data.success) {
							this.itemSearch = response.data.success.data.RowObject;
						}
					})
					.finally(() => {
						this.loading = false;
					});
			},
			beginUpdate() {
				if (!this.updateActive) {
					this.updateActive = true;
					this.dataModified = true;
					this.rowObjectBefore = JSON.parse(
						JSON.stringify(this.rowObject)
					);
				}
			},
			cancelUpdate() {
				this.updateActive = false;
				this.dataModified = false;
				this.rowObject = JSON.parse(JSON.stringify(this.rowObjectBefore));
			},
			saveChanges() {
				let that = this;
				let sdoName = "web/objects/dECatEntry.r";
				let params = {
					action: "update",
					rowObjUpd: [],
				};
				for (let idx = 0; idx < that.rowObject.length; idx++) {
					// before row
					let beforeRecord = that.rowObjectBefore.find(
						(_rowObjectBefore) => {
							return (
								_rowObjectBefore.RowNum ===
								that.rowObject[idx].RowNum
							);
						}
					);
					if (beforeRecord.index_seq_nr != idx + 1) {
						params.rowObjUpd.push(beforeRecord);
						// changed row
						that.rowObject[idx].RowMod = "U";
						that.rowObject[idx].index_seq_nr = idx + 1;
						params.rowObjUpd.push(that.rowObject[idx]);
					}
				}
				caas.sdo(sdoName, params)
					.then((response) => {
						if (response.data.success) {
							this.updateActive = false;
							this.dataModified = false;
							that.rowObjectBefore = [];
							this.$store.dispatch("session/showSuccess");
							this.$store.dispatch(
								"catalogIndex/fetchCatalogEntries"
							);
						}
						if (response.data.error) {
							throw response.data.error;
						}
					})
					.catch((error) => {
						this.$store.dispatch("session/showBottomMessage", error);
					})
					.finally(() => {
						this.loading = false;
					});
			},
		},
	};
</script>
